@import 'src/assets/scss/global_styles';
@import 'src/assets/scss/mixins';

.ant-picker {
  height: 38px;
}

.ant-picker:hover {
  border-color: $accent !important;
}

.ant-picker-panels > *:first-child button.ant-picker-header-next-btn {
  visibility: visible !important;
}

.ant-picker-panels > *:first-child button.ant-picker-header-super-next-btn {
  visibility: visible !important;
}

.ant-picker-panels > *:nth-child(2) {
  display: none;
}

.ant-picker-panel-container,
.ant-picker-footer {
  width: 400px !important;
}

.ant-picker-footer-extra > div {
  flex-wrap: wrap !important;
}

.ant-picker-active-bar {
  background: $accent !important;
}

//___range-picker calendar styles

.createDateRangePicker .ant-picker-input {
  width: 70px !important;
}

.createDateRangePicker .ant-picker-range-wrapper {
  @include serif;
}

.createDateRangePicker .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
.createDateRangePicker .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
  background-color: $accent !important;
}

.createDateRangePicker
  .ant-picker-cell-in-view.ant-picker-cell-today
  .ant-picker-cell-inner::before,
.ant-picker-focused {
  border-color: $accent !important;
}

.createDateRangePicker
  .ant-picker-cell-in-view.ant-picker-cell-range-start:not(
    .ant-picker-cell-range-start-single
  )::before,
.createDateRangePicker .ant-picker-cell-in-view.ant-picker-cell-in-range::before,
.createDateRangePicker
  .ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single)::before,
.createDateRangePicker
  .ant-picker-time-panel-column
  > li.ant-picker-time-panel-cell-selected
  .ant-picker-time-panel-cell-inner {
  background: $accent_lite !important;
}

// .createDateRangePicker .ant-btn-primary {
//   background-color: red !important;
//   border-color: red !important;
// }

.createDateRangePicker .ant-picker-panel-layout {
  flex-direction: row-reverse;

  .ant-picker-presets {
    margin-top: 48px;
  }
}

//___date-picker calendar styles

// .datePicker .ant-picker-panel-container,
// .ant-picker-footer {
//   width: 450px !important;
// }

.datePicker .ant-picker-cell-selected .ant-picker-cell-inner {
  background-color: $accent !important;
}

.datePicker .ant-picker-time-panel-cell-selected .ant-picker-time-panel-cell-inner {
  background-color: $accent_lite !important;
}

.datePicker .ant-picker-time-panel .ant-picker-header .ant-picker-header-view {
  display: none;
}
.datePicker .ant-picker-time-panel .ant-picker-content {
  padding: 2px 0px 5px 0px;
}

.datePicker .ant-btn {
  padding: 0px 12px;
}

.datePicker .ant-btn:not([disabled]) {
  background-color: $accent;
  color: $white !important;
  &:hover {
    background-color: $accent_dark;
  }
}

.ant-picker-content {
  .ant-picker-time-panel-column {
    &::-webkit-scrollbar {
      width: 4px !important;
    }
    &::-webkit-scrollbar-track {
      background: none;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #e5e3e6;
      border-radius: 4px;
    }
  }
}

.ant-picker-now {
  display: none !important;
}
