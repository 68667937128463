$black:#1c1c1c
$accent: #cc33ff
$accent_lite: #F9E6FF
$accent_dark: #BA29EB
$grey:#bab3bc
$borders: #e1dfe2
$background: #fafafa
$white:#ffffff
$child_info:#F5F4F5


//___Chart colors
$dynamic_up: #52C41A
$dynamic_down: #FF2E47
$dynamic_stagnation: #FFB82E

//___Tag colors
$patent_tag_color: #0098ed
$patent_tag_background: #d6eefc
$financing_tag_color: #83c100
$financing_tag_background: #ecf6d6
$target_tag_color: #FFAC0C
$target_tag_background: #FFF2D8