@import 'src/assets/scss/color_variables';

.navbar {
  height: 70px;

  display: flex;
  justify-content: center;

  background-color: $white;
  font-size: 16px;
  color: black;

  .navbar_container {
    width: 100%;
    max-width: 1200px;
    margin: 0 60px;

    display: inherit;
    justify-content: space-between;
    gap: 32px;

    .navbar_logo-menu {
      display: inherit;
      align-items: center;
      gap: 110px;

      ul {
        display: inherit;
        gap: 32px;
        li {
          display: inline;
          // margin-right: 32px;
          // &:last-child {
          //   margin-right: 0;
          // }
          a {
            text-decoration: none;
            color: $black;
          }
          .active {
            font-weight: bold;
          }
        }
      }
    }
  }
}

.navbar_none {
  display: none;
}
