@import 'src/assets/scss/color_variables';
@import 'src/assets/scss/mixins';

.table_container {
  display: flex;
  flex-direction: column;
  gap: 24px;

  .table_navigation_container {
    display: inherit;
    justify-content: space-between;
    align-items: center;
  }

  .table {
    width: 100%;
  }
}

.table_trend_index {
  display: flex;
  gap: 6px;
}

.table_header_link {
    color: $accent;
    &:hover {
    @include pointer;
    color: $accent;
  }
}

.border {
  // background-color: blue;
  border-color: #6d9eff !important;
  // border: 1px solid red !important;
}

.expand-parent {
  background-color: rgba(225, 223, 226, 0.35);
}

.ant-table-wrapper .ant-table {
  // background-color: blue;
  font-family: 'Nunito Sans', sans-serif;
}

.ant-table-wrapper .ant-table-thead > tr > th {
  background-color: $white;
  color: $grey;
  font-weight: 500;
}

:where(.css-dev-only-do-not-override-1ij74fp).ant-table-wrapper tr.ant-table-expanded-row > td,
:where(.css-dev-only-do-not-override-1ij74fp).ant-table-wrapper
  tr.ant-table-expanded-row:hover
  > td {
  background-color: $child_info !important;
}

.ant-table-cell .ant-table-cell-row-hover {
  background-color: rgba(225, 223, 226, 0.35);
  border-radius: 0;
}

:where(.css-dev-only-do-not-override-1ij74fp).ant-table-wrapper tr.ant-table-expanded-row > td,
:where(.css-dev-only-do-not-override-1ij74fp).ant-table-wrapper
  tr.ant-table-expanded-row:hover
  > td {
  background-color: $white;
}

.highlight-bottom-border > td {
  border-bottom: solid 1px black !important;
}

.highlight-top-border > td {
  border-top: solid 2px black !important;
  border-bottom: solid 2px #6d9eff !important;
}

//______description

.ant-descriptions {
  padding-left: 48px;
}

.gap {
  display: flex;
  gap: 8px;
}

//_______pagination

.ant-pagination .ant-pagination-item {
  font-family: 'Nunito Sans', sans-serif;
}

.ant-pagination .ant-pagination-item-active {
  border: 0;
  background-color: $accent;
  a {
    color: $white;
    padding-bottom: 2px;

    &:hover {
      color: $white;
    }
  }
}

// .ant-table-row-level-1 {
//   background-color: $white !important;
// }
