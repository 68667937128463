@import 'src/assets/scss/color_variables';
@import 'src/assets/scss/mixins';

.card_container {
  @include box_shadow;
  @include pointer;
  display: flex;
  flex-direction: column;

  box-sizing: border-box;
  padding: 16px 24px;
  // max-width: 690px;
  // width: 100%;
  max-height: 170px;
  background-color: $white;
  border-radius: 8px;

  // &:not(:last-child) {
  margin-bottom: 8px;
  // }

  .card_tag {
    width: max-content;
    padding: 4px 8px;
    border-radius: 4px;

    margin-bottom: 5px;

    @include tag_text;
  }
  .card_tag_patent {
    background-color: $patent_tag_background;
    color: $patent_tag_color;
  }
  .card_tag_article {
    background-color: $accent_lite;
    color: $accent;
  }
  .card_tag_target {
    background-color: $target_tag_background;
    color: $target_tag_color;
  }
  .card_tag_financing {
    background-color: $financing_tag_background;
    color: $financing_tag_color;
  }
  .card_tag_undef {
    background-color: $borders;
    color: $grey;
  }

  .card_title {
    @include mini_header;
    margin-bottom: 8px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
  }

  .card_primary_id {
    @include modal_input_header;
    color: $grey;
    margin-bottom: 10px;
  }

  .card_abstract {
    @include modal_input_header;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
  }
  .card_target_info {
    @include modal_input_header;
    display: flex;
    gap:24px;
  }
}

.active_card_container {
  // background: linear-gradient(0deg, rgba(0, 0, 0, 0.08), rgba(0, 0, 0, 0.08)), $white;
  // background: rgba(255, 255, 255, 0.48);
  // opacity: 0.5;
  cursor: default;
}
