@mixin pointer {
  cursor: pointer;
}

@mixin header {
  font-weight: 700;
  font-size: 24px;
  line-height: 120%;
}

@mixin mini_header {
  font-weight: 700;
  font-size: 18px;
  line-height: 120%;
}

@mixin modal_input_text {
  font-weight: 400;
  font-size: 18px;
  line-height: 120%;
}

@mixin results_text {
  font-weight: 700;
  font-size: 16px;
  line-height: 140%;
}

@mixin search_text {
  font-weight: 600;
  font-size: 16px;
  line-height: 140%;
}


@mixin results_header_text {
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
}


@mixin description_text {
  font-weight: 600;
  font-size: 14px;
  line-height: 120%;
}

@mixin big_text {
  font-weight: 400;
  font-size: 14px;
  line-height: 130%;
}

@mixin modal_input_header {
  font-weight: 400;
  font-size: 14px;
  line-height: 120%;
}

@mixin tag_text {
  font-weight: 400;
  font-size: 12px;
  line-height: 120%;
}

@mixin count_text {
  font-weight: 600;
  font-size: 10px;
  line-height: 120%;
}

@mixin error_text {
  font-weight: 400;
  font-size: 10px;
  line-height: 120%;
}

@mixin serif {
  font-family: 'Nunito Sans', sans-serif;
}

@mixin box_shadow {
  box-shadow: 0px 2px 20px rgba(202, 202, 224, 0.12);
}
