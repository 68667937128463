.page {
  display: flex;
  justify-content: center;

  .page_container {
    width: 100%;
    max-width: 1200px;
    margin: 32px 60px;

    display: inherit;
    flex-direction: column;
    gap: 32px;
  }
}

.auth_page_container {
  height: 100vh;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 40px;
}

.global_chart_container {
  display: inherit;
  flex-direction: column;
  gap: 32px;
}
