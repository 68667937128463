@import 'src/assets/scss/color_variables';
@import 'src/assets/scss/mixins';

.global_search_box {
  margin-top: 140px !important;
  align-items: center;

  .global_search_container {
    display: inherit;
    flex-direction: column;
    gap: 40px;

    max-width: 700px;
    width: 100%;

    .search_input_box {
      display: inherit;
      flex-direction: column;
      gap: 24px;

      .search_input_container {
        display: inherit;
        gap: 16px;

        height: 48px;

        .search_button {
          height: 100%;
          padding: 0 32px;
        }
      }

      .search_examples_box {
        .search_examples_container {
          &:last-child {
            .search_example_coma {
              display: none;
            }
          }
          .search_example {
            display: inline;

            color: $accent;
            text-decoration: underline;

            @include pointer;
            @include search_text;
          }

          .search_example_coma {
            display: inline;
          }
        }
      }
    }
  }
  .search_update {
    @include results_header_text;
    position: fixed;
    bottom: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 32px;
    color: $grey;
    .search_count_container {
      @include big_text;
      display: inherit;
      gap: 32px;
      .count_block {
        display: inherit;
        gap: 2px;
        flex-direction: column;
        text-align: center;
      }
    }
  }
}
