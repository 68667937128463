@import 'src/assets/scss/color_variables';
@import 'src/assets/scss/mixins';

.ant-select-selection-placeholder,
.ant-select-selection-item,
.ant-select-dropdown,
.ant-dropdown-menu,
.ant-input {
  font-family: 'Nunito Sans', sans-serif;
}

.text_btn_antd {
  color: $accent;
  &:hover:not([disabled]) {
    color: $accent !important;
    background-color: #faebff !important;
  }
}

.text_border_btn_antd {
  color: $accent !important;
  border-color: $accent !important;
  &:hover {
    background-color: #faebff;
  }
}

.btn_antd:not([disabled]) {
  background-color: $accent;
  color: $white !important;
  &:hover {
    background-color: $accent_dark !important;
  }
}

.ant-select-selection-placeholder,
.ant-select-selection-item,
.ant-picker-large .ant-picker-input > input {
  @include modal_input_header;
  padding-inline-end: 15px !important;
}

.source_link_global {
  @include modal_input_header;
  @include pointer;
  color: $accent;
  text-decoration: none;
  display: inherit;
  align-items: center;
  gap: 6px;
  &:hover {
    color: $accent_dark;
  }
}

.source_link_global_disable {
  @include modal_input_header;
  color: $grey;
  text-decoration: none;
  cursor: not-allowed;
  display: inherit;
  align-items: center;
  pointer-events: none;
  gap: 6px;
}

.tooltip_title_global {
  display: flex;
  align-items: center;
  gap: 8px;
}

.recharts-tooltip-cursor {
  fill: $background;
}

.ant-tooltip-content {
  @include serif;
}
