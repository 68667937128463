@import 'src/assets/scss/color_variables';
@import 'src/assets/scss/mixins';

.description_container {
  @include serif;
  .abstract_container {
    padding-right: 32px;
    .left_info_container {
      display: flex;
      flex-direction: column;
      gap: 16px;
      .buttons_section {
        display: inherit;
        gap: 40px;

        .info_button_download {
          @include modal_input_header;
        }
      }
    }
  }
  .financing_container {
    width: 100%;
    .left_info_container {
      display: flex;
      flex-direction: column;
      gap: 16px;
      .buttons_section {
        display: inherit;
        gap: 40px;

        .info_button_download {
          @include modal_input_header;
        }
      }
    }
  }

  .left_info_trends_container {
    display: flex;
    flex-direction: column;
    gap: 24px;
    .left_info_section {
      display: inherit;
      flex-direction: column;
      gap: 12px;
      .info_text {
        @include modal_input_header;
      }
    }
  }
  .right_info_container {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
}
.info_section {
  display: inherit;
  flex-direction: column;
  gap: 8px;

  .info_section_title {
    @include modal_input_header;
    color: $grey;
  }

  .info_section_text {
    @include modal_input_header;

    .author_item {
      &:not(:last-child) {
        margin-bottom: 4px;
      }
    }
    .name_item {
      &:not(:last-child) {
        margin-bottom: 12px;
      }
    }
  }

  .collapse_array {
    @include modal_input_header;
    color: $accent;
    cursor: pointer;
  }
}

// .ant-descriptions-item-content{
//   display: flex !important;
// }

// @media (max-width: 1200px) {
//   .description_container {
//     .abstract_container {
//       width: calc(67% - ((100vw - 700px) / 10) * 1%);
//     }
//   }
// }
