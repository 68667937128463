@import 'src/assets/scss/global_styles';
@import 'src/assets/scss/mixins';

.selecting_container {
  display: flex;
  gap: 16px;
  align-items: center;

  .selecting_section_title {
    @include modal_input_header;
    color: $grey;
  }
}
