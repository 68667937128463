@import 'src/assets/scss/color_variables';

.switch_container {
  display: flex;
  gap: 14px;
  align-items: center;

  .switch_box{
    // background-color: $accent;
    // &:hover{
    // background-color: $accent_dark;
    // }
  }
}
.ant-switch {
    background-color: $borders !important;
}
.ant-switch-checked {
    background-color: $accent !important;
}