@import 'src/assets/scss/color_variables';
@import 'src/assets/scss/mixins';

.search_results_text {
  @include big_text;
  @include serif;
  color: $grey;
}
.search_filter_container {
  display: flex;
  align-items: center;
  gap: 24px;

  .filter_btn {
    padding: 8px 16px 8px 32px !important;
    position: relative;

    svg {
      position: absolute;
      left: 12px;
      top: 50%;
      transform: translateY(-50%);
    }
  }
}

.results_container {
  position: relative;
  display: flex;
  gap: 24px;

  .results_search_container {
    max-width: 690px;
    width: 100%;

    .empty_container {
      margin-top: 60px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 10px;
      .text_container {
        display: inherit;
        flex-direction: column;
        gap: 8px;

        .text_header {
          @include results_header_text;
        }
        .text {
          @include results_text;
          text-align: center;
        }
      }
    }
  }
}
