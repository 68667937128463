@import 'src/assets/scss/color_variables';
@import 'src/assets/scss/mixins';

.no_subs_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;

  max-width: 280px;
  margin: auto;
  margin-top: 100px;

  .no_subs_text {
    @include mini_header;
    display: inherit;
    text-align: center;
  }
  .no_subs_btn_container {
    width: 100%;
  }
}
