@import 'src/assets/scss/color_variables';
@import 'src/assets/scss/mixins';

.info_card_container {
  @include box_shadow;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 16px;
  padding: 24px;
  box-sizing: border-box;
  max-width: 486px;
  width: 100%;
  height: auto;
  max-height: calc(100vh - 48px);
  background: $white;
  border-radius: 8px;
  overflow: auto;

  position: sticky;
  top: 24px;
  bottom: 24px;
  margin-bottom: 64px;

  .cross {
    position: absolute;
    top: 10px;
    right: 10px;

    @include pointer;
  }
  .info_card_description_container {
    .info_card_title {
      @include mini_header;
    }

    .info_card_primary_id {
      @include modal_input_header;
      margin-top: -6px;
    }

    display: inherit;
    flex-direction: column;
    gap: 16px;
    .info_card_description {
      display: inherit;
      flex-direction: column;
      gap: 8px;
      .description_title {
        @include modal_input_header;
        color: $grey;
      }
      .description_text {
        @include big_text;
      }

      .description__relations_text {
        @include modal_input_header;
        display: flex;
        gap: 16px;
        &:not(:last-child) {
          margin-bottom: 10px;
        }
        .relations_uniprot {
          width: 50%;
        }
        .relations_disease {
          width: 50%;
        }
      }
    }
    .description_row_container {
      display: inherit;
      flex-direction: row;
      gap: 20px;
      .row_card {
        display: inherit;
        flex-direction: column;
        gap: 8px;
      }
    }
  }
  .link_container {
    display: inherit;
    margin: 12px 0;
  }

  &::-webkit-scrollbar {
    width: 4px;
  }
  &::-webkit-scrollbar-track {
    background: none;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #e5e3e6;
    border-radius: 4px;
  }
}
