@import 'src/assets/scss/color_variables';
@import 'src/assets/scss/mixins';

.ant-modal {
  .ant-modal-close {
    width: 24px;
    height: 24px;
  }

  .ant-modal-content {
    border-radius: 10px;
    padding: 24px 40px 40px 40px;

    @include serif;

    .ant-modal-header {
      margin-bottom: 40px;
      .ant-modal-title {
        @include header;
      }
    }

    .ant-modal-body {
      .modal_body_section {
        margin-bottom: 24px;
        &:last-child {
          margin-bottom: 0px;
        }
        .section_title {
          @include modal_input_header;
          color: $grey;

          margin-bottom: 8px;
        }
        .section_text {
          @include modal_input_text;
        }

        .input_section_title {
          @include modal_input_header;

          margin-bottom: 16px;
        }
        .input_section_text {
          @include modal_input_text;
        }
      }
    }

    .ant-modal-footer {
      margin-top: 64px;

      display: flex;
      flex-direction: row-reverse;
      justify-content: flex-end;
      gap: 24px;
    }
  }
}
.modal_btns_padding {
  padding: 0px 24px !important;
  margin-inline-start: 0px !important;
}
