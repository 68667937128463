@import 'src/assets/scss/color_variables';
@import 'src/assets/scss/mixins';
.list_box {
  height: 28px;

  display: flex;
  ul {
    display: inherit;
    li {
      display: inline;
      @include pointer;
    }
  }
}

.li_active {
  position: relative;
  border-bottom: 2px solid $accent;
  color: $accent !important;
}

.li_inactive {
  position: relative;
  border-bottom: 2px solid $borders;
}

.response_count {
  @include count_text;
  color: $grey;
  position: absolute;
  top: 0;
  right: -1px;
}
