@import 'src/assets/scss/color_variables';

.loading_page_spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 90vh;

  .loading_page_spinner_inner {
    border: 4px solid #f3f3f3;
    border-top: 4px solid $accent;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 0.8s linear infinite;
  }
}

.loading_spinner {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10;
  background: rgba(255, 255, 255, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;

  .loading_spinner_inner {
    border: 3px solid #fff;
    border-top: 3px solid $accent;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 1s linear infinite;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
