@import 'src/assets/scss/mixins';

.custom-tooltip {
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 1px 10px rgba(0, 0, 0, 0.2);
  padding: 4px 12px;
  text-align: left;
}
.tooltip_value_text{
    @include description_text;
}