@import 'src/assets/scss/color_variables';
@import 'src/assets/scss/global_styles';
@import 'src/assets/scss/mixins';

.filtering_box {
  display: flex;
  gap: 24px;

  .filter_container {
    width: 384px;

    display: inherit;
    flex-direction: column;
    gap: 16px;

    .filter_name {
      @include modal_input_header;
    }
  }
}

.btn_container {
  margin-left: 24px;

  display: inherit;
  align-items: end;
}

.filter {
  @include serif;
  width: 100%;
}
