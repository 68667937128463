@import 'src/assets/scss/color_variables';
@import 'src/assets/scss/global_styles';
@import 'src/assets/scss/mixins';

.filtering_search_box {
  max-width: 690px;
  margin-bottom: 14px;

  display: flex;
  flex-direction: column;
  gap: 24px;

  .filtering_search_container {
    display: inherit;
    justify-content: space-between;

    .filtering_search_container_left {
      max-width: 339px;
      width: 100%;
      display: inherit;
      flex-direction: column;
      gap: 12px;
    }

    .filtering_search_container_right {
      max-width: 339px;
      width: 100%;
      display: inherit;
      flex-direction: column;
      gap: 12px;
    }

    .text_search_container {
      @include modal_input_header;
      height: 40px;
      padding-left: 12px;

      display: inherit;
      gap: 24px;
      align-items: center;
    }
  }
}

.other_info_container {
  display: inherit;
}

.filter {
  @include serif;
  width: 100%;
}

//___Radio styles
.ant-radio-wrapper .ant-radio-checked .ant-radio-inner {
  border-color: $accent;
  background-color: $white;

  // &:hover {
  //   border-color: $accent;
  // }

  &::after {
    background-color: $accent;
  }
}

// .ant-radio-input:hover {
//   border-color: $accent !important;
// }
