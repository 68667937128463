@import 'src/assets/scss/global_styles';
@import 'src/assets/scss/color_variables';
@import 'src/assets/scss/mixins';

.ant-input-suffix {
  display: flex;
  flex-direction: row-reverse;
  gap: 4px;
}

.ant-input-group-addon {
  .ant-btn {
    background-color: $white;
    border: 0px;
    border-left: 1px solid $borders;
    box-shadow: 0 0px 0 rgb(5 145 255 / 10%);

    display: flex;
    align-items: center;
    &:hover {
      background-color: $white;
      path {
        stroke-width: 1.7;
    }
    }
  }
}
