@import 'src/assets/scss/color_variables';

.target_container {
  background-color: $white;

  padding-top: 32px;
  border-radius: 8px;

  display: flex;
  flex-direction: column;
  gap: 48px;
  &.padding {
    padding-left: 24px;
    padding-right: 24px;
  }
}
