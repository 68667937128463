@import 'src/assets/scss/color_variables';
@import 'src/assets/scss/mixins';

.transition_parent {
  background-color: $background;
  .header_class {
    .description_container {
      display: flex;
      flex-direction: column;
      gap: 24px;
      .title_row {
        @include modal_input_header;
        @include serif;
      }
      .description_row {
        @include description_text;
        @include serif;
        word-break: break-word;
      }
    }
  }
}

.desc_array_item {
  &:not(:last-child) {
    margin-bottom: 8px;
  }

  .cat_wrapper{
    color: $grey;
  }
}

.ant-collapse-expand-icon {
  @include pointer;
}

.ant-collapse-header {
  padding: 12px 0px !important;
}
.ant-collapse-header-text {
  @include mini_header;
}
.ant-collapse-content-box {
  padding: 16px 0px !important;
}
