@import 'src/assets/scss/color_variables';

@mixin dynamic_font {
  font-weight: 600;
  font-size: 16px;
  line-height: 120%;
}

.dynamic_trend_container {
  display: flex;
  align-items: center;

  .dynamic_up_text {
    @include dynamic_font;
    color: $dynamic_up;
  }

  .dynamic_down_text {
    @include dynamic_font;
    color: $dynamic_down;
  }

  .dynamic_stagnation_text {
    @include dynamic_font;
    color: $dynamic_stagnation;
  }
}
