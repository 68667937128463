@import 'src/assets/scss/color_variables';
@import 'src/assets/scss/mixins';

.chart_publication_title {
  display: flex;
  flex-direction: column;
  gap: 15px;

  .chart_publication_data_container {
    min-height: 40px;
    display: inherit;
    justify-content: space-between;
    align-items: center;

    .right_data_container{
      display: inherit;
      gap: 32px;
      align-items: center;
    }
  }
}
.chart_header {
  display: inline;
  @include mini_header;
}

.my_bar_rechart{
.recharts-default-legend {
  text-align: left !important;
  padding-left: 58px !important;
  .recharts-legend-item {
    &:last-child {
      float: right;
    }
  }
}
}

.recharts-legend-item-text{
  color: $black !important;
}