@import 'src/assets/scss/color_variables';
@import 'src/assets/scss/mixins';

.chart_index_title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .chart_index_data_container {
    display: flex;
    gap: 24px;
    .index_info_container {
      display: flex;
      align-items: center;
      gap: 6px;

      .tooltip_formula{
        @include results_header_text;
      }
    }

    .chart_header {
      display: inherit;
      @include mini_header;
    }
    .chart_index_container {
      display: inherit;
      align-items: center;
      gap: 12px;
      .chart_index_data {
        @include search_text;
        display: inline;
      }
    }
  }
}


// .ant-tooltip{
//   padding-bottom: 111px;
// }