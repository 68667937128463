@import 'src/assets/scss/color_variables';
@import 'src/assets/scss/mixins';

form {
  width: 464px;
  padding: 32px 40px 40px 40px;
  box-sizing: border-box;

  background-color: $white;
  border-radius: 10px;
  @include box_shadow;

  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 24px;

  .form_name {
    @include header;
    margin-bottom: 16px;
  }

  .body_section {
    width: 100%;
    position: relative;
    display: inherit;
    flex-direction: column;
    gap: 16px;
    .section_title {
      @include modal_input_header;
    }
    .form_input {
      border-radius: 4px;
      height: 48px;
    }
    .error_text_container {
      position: absolute;
      top: 80px;
      height: 24px;
      .error_text {
        @include error_text;
        color: $dynamic_down;
      }
    }
  }
  .form_btn_container {
    margin-top: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 24px;
    .form_button {
      border-radius: 4px !important;
      padding: 0px 24px !important;
    }
    .response_error_text_container {
      height: 24px;
      .response_error_text {
        @include modal_input_header;
        color: $dynamic_down;
      }
    }
  }
}
